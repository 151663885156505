.tooltip-container {
  @apply relative flex flex-col items-center group;
}

.tooltip-hover {
  @apply absolute bottom-0 flex-col items-center
    hidden mb-6 group-hover:flex;
}

.tooltip {
  @apply rounded-md relative z-10 p-2 text-xs leading-none 
    text-white whitespace-nowrap bg-title_purple shadow-lg;
}

.arrow {
  @apply w-3 h-3 -mt-2 transform rotate-45 bg-title_purple;
}
